<template>
  <section class="wa-full-height">

    <div class="columns" style="margin-top: 15px">
      <b-input v-model="videoURL" :value="videoURL" type="is-success" class="column is-9 is-offset-1" ></b-input>
      <b-button class="button is-primary" style="margin-top: 12px" @click="LoadVideo()">
        <strong>Load</strong>
      </b-button>
    </div>
      
    <div id="container" v-show="this.videoSource">      
      <video ref="videoPlayer" class="video-js"></video>   
    </div>
    
  </section>
</template>

<script>

import videojs from 'video.js';
import { registerIVSTech } from 'amazon-ivs-player';
import 'video.js/dist/video-js.css';

export default {
  name: 'VideoPlayer',
  components: {
    
  },
    
  data() {
    return {
      player: null,
      videoSource: ``,
      
      videoOptions: {
        autoplay: true,
        controls: true,
        techOrder: ["AmazonIVS"],
        width: "1600"
      },
      
      videoURL: `https://1cdb0a85ee3d.eu-central-1.playback.live-video.net/api/video/v1/eu-central-1.041126794743.channel.2xIo7vvooNMT.m3u8`,
    }
  },
  
  
  mounted(){
    if (this.$route.params.videourl) {
      this.videoURL = this.$route.params.videourl || this.videoURL
      this.videoSource = this.videoURL;
    }
    
    // register the tech with videojs
    console.log(`wasmWorker: ${this.createAbsolutePath('/assets/amazon-ivs-wasmworker.min.js')}`)

    registerIVSTech(videojs,  {
      wasmWorker: this.createAbsolutePath('/assets/amazon-ivs-wasmworker.min.js'),
      wasmBinary: this.createAbsolutePath('/assets/amazon-ivs-wasmworker.min.wasm'),
    });
    
    // Init the player
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
      console.log('Player is ready to use!');
      // play the stream
      // this.player.src(this.videoSource);
      // this.startVideo();
      
      //https://docs.aws.amazon.com/ivs/latest/userguide/player-videojs.html
    })
    this.startVideo();
  },
  
  
  
  beforeUnmount() {
    // Destroy the player instance
    if(this.player) {
      this.player.dispose();
    }
  },
  
  methods: {    
    startVideo() {
      console.info(`Staring video: `, this.videoSource)
      if (!this.videoSource) {
        console.info(`no video url`)
        return;
      } 
      
      this.player.src(this.videoSource);
    },
    
    LoadVideo() {
      this.videoSource = this.videoURL;
      this.startVideo();
    },
    
    createAbsolutePath(assetPath) {
      console.log( document.URL );
      return new URL(assetPath, document.URL).toString();
    },
  },
};
</script>


<style lang="scss" scoped>
  #container{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    /* justify-content:space-between; */
    /* justify-content:space-around; */
    
    /* align-items: stretch; */
    /* align-items: flex-start; */
    /* align-items: flex-end; */
    /* align-items: center; */
    
    /* flex-wrap: wrap; */
    /* align-content: center; */
    /* align-content: flex-start; */
    /* align-content: flex-end; */
    /* align-content: space-between; */
    
    gap: 10rem;
  }

</style>